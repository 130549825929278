<template>

 <v-main  style="background-color:#F2F3F8">
     <v-container>

<div class="mt-10">

<h2>서비스 이용약관</h2><br><br>

<p class="font-weight-bold">1.정의</p> 

 &nbsp 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br><br>

 &nbsp 1-1.이오스퀘스트 사이트 : 이오스퀘스트가 제공하는 인터넷 관련 서비스를 의미합니다.<br>

 &nbsp 1-2.이용자 : 이오스퀘스트 사이트에 접속하여 이 약관에 따라 이용 계약을 체결하고 이오스퀘스트가 제공하는 서비스를 이용하는 모든 사용자를 의미합니다.<br><br>

 
<p class="font-weight-bold">2.약관의 효력 및 변경</p> 

&nbsp 2-1.이 약관은 이오스퀘스트 사이트를 이용하는 모든 이용자에게 그 효력이 발생합니다.<br>

&nbsp 2-2.이오스퀘스트는 이 약관을 사전 고지 없이 변경할 수 있으며, 변경된 약관은 이오스퀘스트 사이트 내 공지사항에 게시함으로써 그 효력이 발생합니다.<br><br>

 
<p class="font-weight-bold">3.서비스의 제공 및 이용</p> 

&nbsp 3-1.이오스퀘스트는 이용자에게 안정적이고 지속적인 서비스를 제공하기 위해 최선의 노력을 다하고 있습니다.<br>

&nbsp 3-2.이오스퀘스트는 이용자의 개인정보 보호 및 안전한 서비스 제공을 위해 관련 법령 및 이용약관에 따라 이용자의 개인정보를 보호합니다.<br>

&nbsp 3-3.이용자는 이오스퀘스트 사이트를 이용함으로써 이 약관에 동의하는 것으로 간주됩니다.<br>

<br>
 
<p class="font-weight-bold">4.이용자의 권리 및 의무</p> 

&nbsp 4-1.이용자는 이 약관에 따라 이오스퀘스트 사이트를 이용할 수 있습니다.<br>

&nbsp 4-2.이용자는 이오스퀘스트 사이트를 이용함으로써 얻은 정보를 무단으로 복제, 유통, 출판하는 등 상업적으로 이용할 수 없습니다.<br>

&nbsp 4-3.이용자는 이오스퀘스트 사이트를 이용하여 다음과 같은 행위를 할 수 없습니다.<br><br>

<ul class="ml-5">
   <li>타인의 저작권을 침해하는 행위</li>
   <li>타인의 개인정보를 수집, 저장, 유포하는 행위</li>
   <li>스팸 메일을 전송하거나 광고를 유포하는 행위</li>
   <li>불법적인 자료를 게시하거나 유포하는 행위</li>
   <li>상용 목적으로 이용하는 행위 (예: 상업적 광고, 판매활동 등)</li>
</ul>

 
<br>
&nbsp 4-4.이용자는 이오스퀘스트 사이트를 이용하여 타인의 권리나 명예를 침해하는 행위를 할 수 없습니다.<br>

&nbsp 4-5.이용자는 이오스퀘스트 사이트를 이용하여 얻은 정보를 정확하고 성실하게 활용해야 합니다.<br>

&nbsp 4-6.이용자는 이오스퀘스트 사이트를 이용하여 법령 또는 이 약관에 위반하는 행위를 할 수 없으며, 이를 위반할 경우 법적 책임을 지게 됩니다.<br>

<br>
 
<p class="font-weight-bold">5.면책조항</p> 

&nbsp 5-1.이오스퀘스트는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대해 책임을 지지 않습니다.<br>

&nbsp 5-2.이오스퀘스트는 천재지변, 전쟁, 기타 이에 준하는 불가항력적인 사유로 인해 서비스 제공이 불가능한 경우 이에 대한 책임을 지지 않습니다.<br>

<br>
 
<p class="font-weight-bold">6.분쟁의 해결</p> 
&nbsp 6-1.이오스퀘스트와 이용자 간에 발생한 분쟁에 대해 해결을 원하는 경우, 이오스퀘스트는 이용자의 합리적인 요구에 성실히 응답할 것입니다.<br>

&nbsp 6-2.이오스퀘스트와 이용자 간에 발생한 분쟁에 대해 소송이 제기될 경우, 대한민국의 관할 법원을 전속 관할 법원으로 합니다.<br>

<br>
 
<p class="font-weight-bold">7.기타</p> 
&nbsp 7-1.이 약관에 명시되지 않은 사항에 대해서는 관계 법령 및 이오스퀘스트 사이트 내 공지사항 등에 따릅니다.<br>

&nbsp 7-2.이 약관은 대한민국의 법령에 따라 규정되며, 이 약관 또는 이용과 관련하여 발생하는 모든 분쟁에 대해서는 대한민국의 법률을 적용합니다.<br>
 
</div>
     </v-container>
 </v-main>



  
</template>

<script>
export default {
 mounted() {
   
     this.$vuetify.goTo(0)
  },
}
</script>

 